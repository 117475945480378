*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;  
}

body {
  margin: 0;
}

.leaflet-container {
  width: 100%;
  height: 500px;
}