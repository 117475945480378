@font-face {
  font-family: 'RobotoRegular';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('./assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: url('./assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2');
  font-display: block;
}

